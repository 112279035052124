import React, { useState, useContext, useEffect } from 'react';
import "./checkoutForm.css";
import { useNavigate } from 'react-router-dom';
import { CheckoutContext } from './CheckoutContainer';
import ScrollToTopOnMount from './general/ScrollToTopOnMount';
import moment from 'moment-timezone';

function CheckoutForm() {
  const navigate = useNavigate();
  const { checkoutData, updateCheckoutData } = useContext(CheckoutContext);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [canChangeSchedule, setCanChangeSchedule] = useState(false);
  const [requestCallBeforeArrival, setRequestCallBeforeArrival] = useState(false);

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
    return 'undefined';
  };

  useEffect(() => {
    // Creating the Google Maps script
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    googleMapsScript.async = true;
    googleMapsScript.onload = () => initAutocomplete();
    document.body.appendChild(googleMapsScript);

    // Creating the script for Zendesk (or similar)
    const zendeskScript = document.createElement('script');
    zendeskScript.type = 'text/javascript';
    zendeskScript.async = true;
    zendeskScript.onload = () => {
      window.zE(function() {
        window.zE.activate();
      });
    };
    document.body.appendChild(zendeskScript);

    // Cleanup function for removing scripts when component unmounts
    return () => {
      document.body.removeChild(googleMapsScript);
      document.body.removeChild(zendeskScript);
    };
  }, []);


  const initAutocomplete = () => {
    const autocompleteInput = document.getElementById('autocomplete');
    const autocompleteOptions = {
      componentRestrictions: { country: 'us' },
      fields: ['formatted_address', 'geometry', 'address_components'],
      types: ['address'],
    };
    const autocompleteInstance = new window.google.maps.places.Autocomplete(autocompleteInput, autocompleteOptions);
    setAutocomplete(autocompleteInstance);
  
    let inputActive = false;
  
    autocompleteInstance.addListener('place_changed', () => {
      // console.log("place changed")
      const place = autocompleteInstance.getPlace();
      updateCheckoutData('address', place.formatted_address);
      updateAddressComponents(place.address_components);
    });
  
    autocompleteInput.addEventListener('input', (event) => {
      updateCheckoutData('address', event.target.value);
    });
  
    autocompleteInput.addEventListener('focus', () => {
      inputActive = true;
    });
  
    autocompleteInput.addEventListener('blur', () => {
      inputActive = false;
    
      // Only attempt to autocomplete if the input value is significantly different from the stored address
      if (!inputActive && checkoutData.address && 
          !autocompleteInput.value.includes(checkoutData.address.split(',')[0])) {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions(
          {
            input: autocompleteInput.value,
            componentRestrictions: { country: 'us' },
            types: ['address'],
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions.length > 0) {
              const firstPrediction = predictions[0];
              const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
              placesService.getDetails(
                { placeId: firstPrediction.place_id, fields: ['formatted_address', 'address_components'] },
                (place, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // Only update if the new address is significantly different
                    if (place.formatted_address !== checkoutData.address) {
                      updateAddressComponents(place.address_components);
                      updateCheckoutData('address', place.formatted_address);
                      autocompleteInput.value = place.formatted_address;
                    }
                  }
                }
              );            
            }
          }
        );
      }
    });
  };

  const updateAddressComponents = (addressComponents) => {
    let zipcode = '';
    let city = '';
    let state = '';

    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes('postal_code')) {
        zipcode = component.long_name;
      }

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }
    });

    if (!checkoutData.zipcode || checkoutData.zipcode !== zipcode) {
      updateCheckoutData('zipcode', zipcode);
    }
    updateCheckoutData('city', city);
    updateCheckoutData('state', state);
  };

  // console.log(CheckoutContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value)
    updateCheckoutData(name, value);
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  const handleBackClick = () => {
    navigate('/date');
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!checkoutData.firstName) {
      errors.firstName = 'First name is required';
      isValid = false;
    }

    if (!checkoutData.lastName) {
      errors.lastName = 'Last name is required';
      isValid = false;
    }

    if (!checkoutData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(checkoutData.email)) {
      errors.email = 'Email is invalid';
      isValid = false;
    }

    if (!checkoutData.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
      isValid = false;
    } else if (!/^\d{10}$/.test(checkoutData.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
      isValid = false;
    }

    if (!checkoutData.address) {
      errors.address = 'Address is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log(checkoutData);
    // if (!validateForm()) {
    //   return;
    // }
  
    try {
      const bookingNotes = checkoutData.notes ? checkoutData.notes.trim() : '';
      const scheduleChangeNote = canChangeSchedule ? 'Customer may want to change the drip selection on-site with a Luxe Nurse\'s guidance.' : '';
      const callBeforeArrivalNote = requestCallBeforeArrival ? 'Customer requested a call from a Luxe Nurse before their arrival on-site.' : '';
      const fullBookingNotes = `${bookingNotes}\n${scheduleChangeNote}\n${callBeforeArrivalNote}`.trim();
     
      // Extract the street address from the formatted address
      const addressParts = checkoutData.address.split(',');
      const streetAddress = addressParts[0].trim();
  
      const patientInfo = {
        firstName: checkoutData.firstName.trim(),
        lastName: checkoutData.lastName.trim(),
        email: checkoutData.email.trim(),
        phone: checkoutData.phoneNumber.trim(),
        address1: streetAddress,
        address2: checkoutData.aptNumber ? checkoutData.aptNumber.trim() : '',
        city: checkoutData.city,
        state: checkoutData.state,
        zipcode: checkoutData.zipcode,
      };
      // console.log("patientInfo", patientInfo);
      const selectedDate = moment(checkoutData.selectedDate).format('YYYY-MM-DD');
      const selectedTime = moment(checkoutData.selectedTime[0], 'hh:mm A').format('HH:mm');
      const startTime = moment.tz(`${selectedDate} ${selectedTime}`, 'YYYY-MM-DD HH:mm', 'America/Chicago');
      // const bookingNotes = checkoutData.notes ? checkoutData.notes.trim() : '';
      const serviceUuid = checkoutData.selectedServiceDetails.uuid;
      const cityId = checkoutData.cityID;
      const availableStaff = checkoutData.selectedTime[1];
      const cookieInfo = {
        loggedInUserId: 0,
        source: 'websiteBooking',
        gclid: getCookie('gclid'),
        lpUrl: getCookie('lpurl'),
        ad: getCookie('ad'),
        adGroup: getCookie('ag'),
        campaign: getCookie('c'),
        keyword: getCookie('k'),
        matchType: getCookie('m'),
      };
      const lpUrl = window.location.href;
  
      const response = await fetch('/api/createBookingWithSelectedStaff', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userInfo: patientInfo,
          startTime,
          bookingNotes: fullBookingNotes,
          serviceUuid,
          cityId,
          availableStaff,
          cookieInfo,
          lpUrl,
          sessionId: checkoutData.sessionId,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        navigate('/confirmed');
        // console.log(data);
        const button = document.getElementById('submitBooking');
        if (button) {
          button.classList.add('green');
          setTimeout(() => button.classList.remove('green'), 3000);
        }
      } else {
        console.error('Error creating booking:', data.error);
        setErrorMessage('An error occurred while creating the booking. Please try again or contact customer support.');
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      setErrorMessage('An error occurred while creating the booking. Please try again or contact customer support.');
    }
  }

  return (
    <form onSubmit={handleSubmit} className='checkout-form checkout'>
      <ScrollToTopOnMount />
      <div className="layout-wrapper">
        <div className="left-section">
          <h2>Booking Details</h2>
          <div className='booking-details-subheading'>
            <p>{checkoutData.selectedServiceDetails.name}: ${checkoutData.selectedServiceDetails.price} </p>
            {/* {console.log(checkoutData)} */}
            <p>
              {checkoutData.selectedDate && new Date(checkoutData.selectedDate).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })} at {checkoutData.selectedTime && checkoutData.selectedTime[0]}: {checkoutData.selectedServiceDetails.duration} Minutes
            </p>
            {/* <p>{checkoutData.selectedServiceDetails.duration} Minutes</p> */}
            {/* <p>${checkoutData.selectedServiceDetails.price}</p> */}
          </div>
          <hr />
          {/* <p style={{ marginTop: "0", fontWeight: "300" }}>Payment is collected at the time of appointment.</p> */}
          <div className="checkout-container">
            {/* <div className="checkout-row input-field one-row">
  <label htmlFor="canChangeSchedule">
    <input
      type="checkbox"
      name="canChangeSchedule"
      id="canChangeSchedule"
      checked={canChangeSchedule}
      onChange={() => setCanChangeSchedule(!canChangeSchedule)}
    />
    I'm not sure I chose the right drip. I'd like the option to change my drip selection on-site with a Luxe Nurse's guidance. (FREE)
  </label>
</div>

<div className="checkout-row input-field  one-row">
  <label htmlFor="requestCallBeforeArrival">
    <input
      type="checkbox"
      name="requestCallBeforeArrival"
      id="requestCallBeforeArrival"
      checked={requestCallBeforeArrival}
      onChange={() => setRequestCallBeforeArrival(!requestCallBeforeArrival)}
    />
    I'd like to receive a call from a Luxe Nurse <b>before</b> they arrive on-site.
  </label>
</div> */}
            <div className="checkout-row input-field">

              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={checkoutData && checkoutData.firstName}
                onChange={handleChange}
                required
                autoComplete="given-name"
              />
              {formErrors.firstName && <span className="error">{formErrors.firstName}</span>}
            </div>
            <div className="checkout-row input-field">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={checkoutData && checkoutData.lastName}
                onChange={handleChange}
                required
                autoComplete="family-name"
              />
              {formErrors.lastName && <span className="error">{formErrors.lastName}</span>}
            </div>
            <div className="checkout-row input-field">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="email"
                id="email"
                value={checkoutData && checkoutData.email}
                onChange={handleChange}
                required
                autoComplete="email"
              />
              {formErrors.email && <span className="error">{formErrors.email}</span>}
            </div>
            <div className="checkout-row input-field">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                name="phoneNumber"
                value={checkoutData && checkoutData.phoneNumber}
                onChange={handleChange}
                required
                autoComplete="tel"
              />
              {formErrors.phoneNumber && <span className="error">{formErrors.phoneNumber}</span>}
            </div>

            <div className="checkout-row input-field">
              <label htmlFor="autocomplete">Address:</label>
              <input
                type="text"
                id="autocomplete"
                name="street"
                value={checkoutData && checkoutData.address}
                onChange={handleChange}
                required
                // autoComplete="street-address"
                // autoComplete="nope"
                autoComplete="off"
              />
              {formErrors.address && <span className="error">{formErrors.address}</span>}
            </div>
            <div className="checkout-row input-field">
              <label htmlFor="aptNumber">Apt/Suite Number (optional):</label>
              <input
                type="text"
                name="aptNumber"
                value={checkoutData && checkoutData.aptNumber}
                onChange={handleChange}
                autoComplete="address-line2"
              />
            </div>
            <div className="checkout-row input-field">
        <label htmlFor="zip">Zipcode:</label>
        <input
          type="text"
          name="zipcode"
          id="zip"
          value={checkoutData.zipcode}
          onChange={handleChange}
          required
          autoComplete="postal-code"
        />
        {formErrors.zipcode && <span className="error">{formErrors.zipcode}</span>}
      </div>
            <div className="checkout-row input-field">
              <label htmlFor="notes">Other Notes (Gate code, etc):</label>
              <textarea name="notes" value={checkoutData && checkoutData.notes} onChange={handleChange} />
            </div>
          </div>
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <div style={{ width: "100%", textAlign: "left" }}>
            <br />
            <button type="submit" className='checkout-navigation-button' style={{ margin: "auto" }}>Book Now</button><br /><br />
            <button className='checkout-navigation-button' style={{ margin: "auto" }} onClick={handleBackClick}>Back</button>
          </div>
        </div>
        {/* <div className="right-section-final"> */}

        {/* </div> */}
      </div>
    </form>
  );
}

export default CheckoutForm;