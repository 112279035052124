import React, { useState, createContext, useEffect } from 'react';
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import SelectDateTime from './CheckoutDate';
import CheckoutForm from './CheckoutForm';
import CheckoutCity from './CheckoutCity';
import BookingConfirmed from './BookingConfirmed'; // Import the new component
import { saveToLocalStorageWithExpiration, loadFromLocalStorageWithExpiration } from './utils/storageManagement';

// Create a context to share state across components
const CheckoutContext = createContext({});

const CheckoutContainer = () => {

  const getServiceDetails = async (serviceId) => {
    // console.log("here")    
    const response = await fetch('/api/getServiceDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid: serviceId})
    });
    const data = await response.json();
    // console.log('data', data);
    updateCheckoutData('selectedServiceDetails', data);
};


  const [checkoutData, setCheckoutData] = useState(()=>{
    
    const savedData = loadFromLocalStorageWithExpiration('checkoutData');
    return savedData || {
    cityID: 1,
    selectedDate: null,
    selectedTime: '',
    userData: {}, 
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    street: '',
    aptNumber: '',
    city: '',
    zipcode:'',
    state: '',
    notes: '',
    selectedServiceUUID: '',
    selectedServiceDetails: {},
    sessionId: null, // Add this line to store the session ID
  }});

  const location = useLocation();

  const initiateSession = async (serviceUuid, cityCalendarId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get('gclid') || '';
    const adSource = urlParams.get('ad') || '';
    const lpUrl = window.location.href;

    try {
      const response = await fetch('/api/initiateSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          serviceUuid,
          cityCalendarId,
          gclid,
          adSource,
          lpUrl,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        updateCheckoutData('sessionId', data.sessionId);
      } else {
        console.error('Failed to initiate session');
      }
    } catch (error) {
      console.error('Error initiating session:', error);
    }
  };

  const updateSessionPath = async (path) => {
    if (checkoutData.sessionId) {
      try {
        await fetch('/api/updateSessionPath', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId: checkoutData.sessionId,
            path,
          }),
        });
      } catch (error) {
        console.error('Error updating session path:', error);
      }
    }
  };

  const updateSessionSlots = async (sessionId, slots) => {
    // console.log('Slots to update:', JSON.stringify(slots, null, 2));

    try {
      await fetch('/api/updateSessionSlots', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          slots,
        }),
      });
    } catch (error) {
      console.error('Error updating session slots:', error);
    }
  };

  useEffect(() => {
    updateSessionPath(location.pathname);
  }, [location]);

  useEffect(() => {
    // Save `checkoutData` to localStorage with a 4-hour expiration
    saveToLocalStorageWithExpiration('checkoutData', checkoutData, 4 * 60 * 60 * 1000); // 4 hours in milliseconds
}, [checkoutData]);

  const updateCheckoutData = (field, value) => {
    setCheckoutData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <CheckoutContext.Provider value={{ checkoutData, updateCheckoutData, getServiceDetails, initiateSession, updateSessionSlots}}>
        <Routes>
          <Route path={"/city/:serviceuuid/:cityName?"} element={<CheckoutCity />} />
          <Route path={"/date/:serviceuuid?/:cityID?"} element={<SelectDateTime />} />
          <Route path="/form" element={<CheckoutForm />} />
          <Route path="/confirmed" element={<BookingConfirmed />} /> {/* Add this line */}
        </Routes>
    </CheckoutContext.Provider>
  );
};

export default CheckoutContainer;
export { CheckoutContext };
