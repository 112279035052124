// Utility to save data with expiration
const saveToLocalStorageWithExpiration = (key, value, ttl) => {
    const now = new Date();
    // `data` is an object which contains the actual data and the expiration time
    const data = {
        value: value,
        expiration: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(data));
};

// Utility to load data, checking if it's expired
const loadFromLocalStorageWithExpiration = (key) => {
    const dataStr = localStorage.getItem(key);
    if (!dataStr) {
        return null;
    }
    const data = JSON.parse(dataStr);
    const now = new Date();
    if (now.getTime() > data.expiration) {
        localStorage.removeItem(key);
        return null;
    }
    return data.value;
};

export { saveToLocalStorageWithExpiration, loadFromLocalStorageWithExpiration };