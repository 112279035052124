import React, { useContext } from 'react';
import { CheckoutContext } from './CheckoutContainer';
import './bookingConfirmed.css';
import './checkoutForm.css';

const BookingConfirmed = () => {
  const { checkoutData } = useContext(CheckoutContext);

  return (
    <div className="booking-confirmed-container">
      <div className="booking-header">
        <h1>Thank you, {checkoutData.firstName} {checkoutData.lastName}</h1>
        <p>You'll receive a confirmation email soon.</p>
        {/* <div className="order-number">Order number: <strong>####</strong></div> */}
      </div>
      
      <div className="booking-details">
        <div className="service-image">
          {/* <img src="path_to_image.jpg" alt={checkoutData.selectedServiceDetails.name} /> */}
        </div>
        <div className="service-info">
          <h2>{checkoutData.selectedServiceDetails.name}</h2>
          <p>Pay in person</p>
          <p>${checkoutData.selectedServiceDetails.price}.00</p>
          <p>Check your email to make sure this booking is confirmed.</p>
          <br/>
          <p>{checkoutData.selectedDate ? new Date(checkoutData.selectedDate).toLocaleDateString() : 'N/A'} at {checkoutData.selectedTime[0]}</p>
          <p>{checkoutData.selectedServiceDetails.duration} Minutes</p>
          <p>{checkoutData.address}</p>
          {/* <button>Add to My Google Calendar</button> */}
        </div>
      </div>

      <div className="booking-summary">
        <div className="summary-row">
          <span>Subtotal</span>
          <span>${checkoutData.selectedServiceDetails.price}.00</span>
        </div>
        <div className="summary-row">
          <span>Sales Tax</span>
          <span>$0.00</span>
        </div>
        <div className="summary-total">
          <span>Total</span>
          <span>${checkoutData.selectedServiceDetails.price}.00</span>
        </div>
      </div>

      <div className="confirmation-footer">
        <button className="checkout-navigation-button" style={{width:"250px"}} onClick={() => window.location.href = '/'}>Return to Homepage</button>
      </div>
    </div>
  );
};

export default BookingConfirmed;
