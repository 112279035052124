import React, { lazy, Suspense } from 'react';
import { Route, Routes, Router } from 'react-router-dom';
import CheckoutDate from '../components/CheckoutDate.js'
import CheckoutForm from '../components/CheckoutForm.js'
import CheckoutCity from '../components/CheckoutCity.js'

const renderLoader = () => <p>Loading</p>;

export default (
  <>
    {/* <Route
      path="/checkoutForm"
      element={ <CheckoutForm/>}
    />
    <Route
      path="/checkoutCity"
      element={ <CheckoutCity/>}
    />
    <Route
      path="/checkoutDate"
      element={ <CheckoutDate/>}
    /> */}
  </>
);
