import React, { useState, useContext, useEffect } from 'react';
import './checkoutForm.css';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckoutContext } from './CheckoutContainer';
import { Helmet } from 'react-helmet';
import ScrollToTopOnMount from './general/ScrollToTopOnMount';

const CheckoutCity = () => {
  let { serviceuuid, cityName } = useParams();
  const { checkoutData, updateCheckoutData, getServiceDetails, initiateSession } = useContext(CheckoutContext);
  const navigate = useNavigate();
  const [cityCalendars, setCityCalendars] = useState([]);
  const [isServiceDetailsFetched, setIsServiceDetailsFetched] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

    // City ID to Name mapping
    const cityMapping = {
      1: 'Houston',
      2: 'Austin',
      3: 'Maddisonville',
      4: 'RGV',
    };


  // Helper function to get the city name based on ID
  const getCityName = (cityId) => {
    return cityMapping[cityId] || 'Houston';
  };

  const canonicalUrl = `https://www.luxemobileiv.com/city/${serviceuuid}/${cityName ? cityName : ''}`;



  useEffect(() => {
    if (isComponentMounted && isServiceDetailsFetched && serviceuuid && !checkoutData.sessionId) {
      initiateSession(serviceuuid, checkoutData.cityID);
    }
  }, [isComponentMounted, isServiceDetailsFetched, serviceuuid, checkoutData.cityID, checkoutData.sessionId]);

  
  function isNumber(value) {
    return /^\d+$/.test(value);
  }
  useEffect(() => {
    const fetchServiceDetails = async () => {
      if (isServiceDetailsFetched) return;
      // console.log(serviceuuid);
      if (serviceuuid) {
        try {
          await getServiceDetails(serviceuuid);
          // console.log('checkoutData.selectedServiceDetails', checkoutData.selectedServiceDetails);
          localStorage.setItem('serviceuuid', serviceuuid);
          localStorage.setItem('serviceuuidTimestamp', Date.now());
          setIsServiceDetailsFetched(true); // Set this after successful fetch and storage
        } catch (error) {
          console.error('Failed to fetch service details:', error);
          // Handle the error, e.g., show an error message to the user
        }
      } else {
        const storedServiceuuid = localStorage.getItem('serviceuuid');
        const storedServiceuuidTimestamp = localStorage.getItem('serviceuuidTimestamp');
        if (storedServiceuuid && storedServiceuuidTimestamp) {
          const currentTime = Date.now();
          const storedTime = parseInt(storedServiceuuidTimestamp, 10);
          const timeDifference = currentTime - storedTime;
          const hoursDifference = timeDifference / (1000 * 60 * 60);
          if (hoursDifference <= 24) {
            try {
              await getServiceDetails(storedServiceuuid);
            } catch (error) {
              console.error('Failed to fetch service details:', error);
              // Handle the error, e.g., show an error message to the user
            }
          } else {
            localStorage.removeItem('serviceuuid');
            localStorage.removeItem('serviceuuidTimestamp');
          }
        }
      }
      setIsServiceDetailsFetched(true); // Set this after successful fetch
    };

    fetchServiceDetails();
  }, [serviceuuid, getServiceDetails, isServiceDetailsFetched]);

  useEffect(() => {
    const fetchCityCalendars = async () => {
      try {
        const response = await fetch('/api/getCityCalendars', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        // console.log(data);
        if (Array.isArray(data)) {
          setCityCalendars(data);
        }
      } catch (error) {
        console.error('Failed to fetch city calendars:', error);
        // Handle the error, e.g., show an error message to the user
      }
    };

    fetchCityCalendars();
  }, []);

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    const autoAdvance = async () => {
      // const { cityID } = useParams();


      if (isComponentMounted && isServiceDetailsFetched) {
        // const urlParams = new URLSearchParams(window.location.search);
        // const cityParam = urlParams.get('city');
        let cityID;
        console.log(cityName)
        if (isNumber(cityName)) {
          cityID=cityName;
          // if (cityName.toLowerCase() === 'houston') {
          //   cityID = '1';
          // } else if (cityName.toLowerCase() === 'maddisonville') {
          //   cityID = '3';
          // } else if (cityName.toLowerCase() === 'rgv') {
          //   cityID = '4';
          // } else if (cityName.toLowerCase() === 'austin') {
          //   cityID = '5';
          // }
        } else {
          cityID = localStorage.getItem('cityID');
        }

        if (cityID) {
          updateCheckoutData('cityID', cityID);
          const selectedServiceuuid = serviceuuid || localStorage.getItem('serviceuuid');
          if (selectedServiceuuid) {
            try {
              await getServiceDetails(selectedServiceuuid);
              // Wait for a short delay to ensure the service details are stored before navigating
              await new Promise(resolve => setTimeout(resolve, 500));
              navigate(`/date/${selectedServiceuuid}`);
            } catch (error) {
              console.error('Failed to fetch service details:', error);
              // Handle the error, e.g., show an error message to the user
            }
          } else {
            console.error('Service UUID is missing');
            // Handle the case when service UUID is missing, e.g., show an error message to the user
          }
        }
      }
    };

    autoAdvance();
  }, [isComponentMounted, isServiceDetailsFetched, serviceuuid]);

  const handleClick = async (cityID) => {
    updateCheckoutData('cityID', cityID);
    localStorage.setItem('cityID', cityID);
    localStorage.setItem('cityIDTimestamp', Date.now());
    const selectedServiceuuid = serviceuuid || localStorage.getItem('serviceuuid');
    if (selectedServiceuuid) {
      try {
        await getServiceDetails(selectedServiceuuid);
        navigate(`/date/${selectedServiceuuid}`);
      } catch (error) {
        console.error('Failed to fetch service details:', error);
        // Handle the error, e.g., show an error message to the user
      }
    } else {
      console.error('Service UUID is missing');
      // Handle the case when service UUID is missing, e.g., show an error message to the user
    }
  };


  const getCitySEOData = () => {
    const cityFullName = getCityName(cityName);
    const serviceName = checkoutData?.selectedServiceDetails?.name || 'IV Therapy';
    
    return {
      title: `${serviceName} in ${cityFullName} | Luxe Mobile IV`,
      description: `Experience premium ${serviceName} in ${cityFullName} with Luxe Mobile IV. Book your appointment today for top-quality mobile IV services.`,
      canonical: canonicalUrl,
      schema: {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": serviceName,
        "provider": {
          "@type": "LocalBusiness",
          "name": "Luxe Mobile IV",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": cityFullName,
            "addressRegion": "TX",
            "postalCode": "77027"
          },
        },
        "areaServed": {
          "@type": "Place",
          "name": cityFullName,
        },
      },
    };
  };

  const seoData = getCitySEOData();


  return (
    <div className="city-selection-container checkout" style={{ minHeight: "80vh" }}>
          <ScrollToTopOnMount />
          <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <link rel="canonical" href={seoData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(seoData.schema)}
        </script>
      </Helmet>

      <h2>Select Your City</h2>
      <div className="city-options">
        {cityCalendars.sort((a, b) => a.id - b.id).map((city) => (
          <button
            key={city.id}
            className="checkout-navigation-button"
            onClick={() => handleClick(city.id)}
          >
            {city.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CheckoutCity;