import React, { Component, lazy, Suspense } from 'react';
import { Routes } from 'react-router-dom';
import routes from '../router/routes';
import CheckoutContainer from './CheckoutContainer';
import NavBar from './general/navbar';
import FooterPage  from './general/footer';

class Main extends Component {
  render() {

    return (
      <div className="App">
        <header className="App-header">
        <NavBar/>
        <div className="navbarSpacer">
          <Routes>{routes}</Routes>
          <CheckoutContainer/> 
          </div>
        </header>
        <FooterPage/>

      </div>
    );
  }
}

export default Main;
